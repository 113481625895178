import { render, staticRenderFns } from "./HeroSliderCM.vue?vue&type=template&id=6dde0da5&scoped=true&"
import script from "./HeroSliderCM.vue?vue&type=script&lang=js&"
export * from "./HeroSliderCM.vue?vue&type=script&lang=js&"
import style0 from "./HeroSliderCM.vue?vue&type=style&index=0&id=6dde0da5&prod&lang=css&"
import style1 from "./HeroSliderCM.vue?vue&type=style&index=1&id=6dde0da5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dde0da5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIReviewsAlt: require('/opt/build/repo/components/UI/ReviewsAlt.vue').default})
