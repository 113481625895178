
    import SsrCarousel from 'vue-ssr-carousel'
    // eslint-disable-next-line no-unused-vars
    import ssrCarouselCss from 'vue-ssr-carousel/index.css'
    import optionsData from '@/data/options.json';
    import bannerData from '@/data/banner.json';
    
    export default {
      components: { SsrCarousel },
      props:{
        region:{
          type:String,
          default:'UK',
        },
        offerspage:{
          type: String,
          default: 'false'
        }
      },
      data(){
          return{
            options: optionsData,
            banner: bannerData
          }
        },
      computed: {
        early() {
          const d = new Date();
          const bfDate = new Date('2023-11-24')
          if (d < bfDate){
            return true;
          } else {
            return false
          }
        },
        currentMonth() {
          const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
          const d = new Date();
          const name = month[d.getMonth()];
          return name
        },
        discount_amount(){
          const amount = this.$props.region === 'US' ? this.banner.discount_amount_us_bf : this.banner.discount_amount_bf;
          return amount
        },
        headline1(){
          const headline = this.$props.region === 'US' ? this.banner.slider_headline_1_us : this.banner.slider_headline_1;
          return headline
        },
        headline2(){
          const headline2 = this.$props.region === 'US' ? this.banner.slider_headline_2_us : this.banner.slider_headline_2;
          return headline2
        },
        subheading(){
          const subheading = this.$props.region === 'US' ? this.banner.slider_subheading_us : this.banner.slider_subheading;
          return subheading
        }
      }
    }
    