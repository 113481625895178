
import { mapGetters } from 'vuex'
import bannerData from '@/data/banner.json'
export default {
  layout: 'Homepage',
  async asyncData({ $page, store, route }) {
    if ($page) return { page: $page }
    else return { page: await store.dispatch('pages/getPage', route.fullPath) }
  },
  data() {
return {
    banner: bannerData,
    isVisible: false,
  };
},
  head() {
    const title = this.page.seo.fullHead.child
      .map((el) => (el.tag === 'title' ? el.child[0].text : null))
      .filter((el) => el)[0]
    const metas = this.page.seo.fullHead.child.filter(
      (tag) => tag.node === 'element'
    )
    const metasArray = metas.filter((m) => m.tag === 'meta').map((m) => m.attr)
    const linkArray = metas.filter((m) => m.tag === 'link').map((m) => m.attr)
    const scriptArray = metas.filter((m) => m.tag === 'script')
    const meta = []
    const link = []
    const script = []

    if(process.env.ROBOTS === 'LIVE'){
      metasArray.push({name:'robots', content:'index, follow'})
    } else {
      metasArray.push({name:'robots', content:'noindex, nofollow'})
    }

    for (let i = 0; i < metasArray.length; i++) {
      const obj = {}
      if (metasArray[i].name) obj.name = metasArray[i].name
      if (metasArray[i].property) obj.property = metasArray[i].property
      obj.content =
        typeof metasArray[i].content === 'string'
          ? metasArray[i].content.replace(
              /(?<!\w-)\b\w+\.tefl\.org\b/g,
              'www.tefl.org'
            )
          : metasArray[i].content.join(' ')

      meta.push(obj)
    }
    for (let i = 0; i < linkArray.length; i++) {
      const obj = {}
      obj.rel = linkArray[i].rel
      obj.href = linkArray[i].href.replace(
        /(?<!\w-)\b\w+\.tefl\.org\b/g,
        'www.tefl.org'
      )
   
      link.push(obj)
      link.push({rel:"alternate",hreflang:'en',href:"https://www.tefl.org/"})
      link.push({rel:"alternate",hreflang:'x-default',href:"https://www.tefl.org/"})
      link.push({rel:"alternate",hreflang:'en-US',href:"https://www.tefl.org/en-us/"})
      link.push({rel:"alternate",hreflang:'en-IE',href:"https://www.tefl.org/ie/"})
    }
    for (let i = 0; i < scriptArray.length; i++) {
      const obj = {}
      obj.class = scriptArray[i].attr.class
      obj.type = scriptArray[i].attr.type
      obj.innerHTML = `${scriptArray[i].child[0].text.replace(
        /(?<!\w-)\b\w+\.tefl\.org\b/g,
        'www.tefl.org'
      ).replace('"name":""','"name":"Home"')}`
      // Fix for missing name in breacrumb schema
      // obj.innerHTML = `${scriptArray[i].child[0].text.replace(
      //   '"name":""','"name":"Home"'
      // )}`
      
      script.push(obj)
    }
    return {
      /* eslint-disable */
      title: title,
      meta: meta,
      link: link,
      script: script,
      __dangerouslyDisableSanitizers: ['script'],
      /* eslint-enable */
    }
  },
  computed: {
    ...mapGetters({
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
  },
  mounted(){
    const query = window.location.search;
    if(this.detectedRegion === 'en-us')
    window.location.pathname = '/en-us/';
  }
}
